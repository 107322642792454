//
// Images
// --------------------------------------------------

// Figures
.figure {
  position: relative;
  padding: .9375rem;
  > .badge {
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    margin: 0;
    z-index: 5;
  }
}
.figure-img {
  display: block;
  width: 100%;
  margin-bottom: .9375rem;
}
.figure-caption { 
  font-weight: 600;
  font-size: .9rem;
  color: $gray-500; 
}
