// 
// user.scss
// Use this to write your custom SCSS
//

// GLOBAL

body{
	color: #000;
	font-size: 1.1rem;
	padding-top: 71px !important;
}

.btn-style-4{
	border-radius: 8px;
	box-shadow: $pagination-link-box-shadow;
}

.btn-style-4:hover{
	color: $primary !important;
	background-color: #fff;
    box-shadow: none;
}
.btn-style-4:active{
	background-color: #fff;
	box-shadow: none;
}

.page-title .container{
	text-align: center !important;
}

.page-title-heading {
	font-family: cortado, sans-serif;
	font-style: normal;
	font-weight: 400;
}

p a{
	text-decoration: none;
	font-weight: 700;
	transition: all .25s;
}

p a:hover{
	color: $theme-blue;
}

// NAVBAR

.navbar{
	padding: 10px 0;
}
.nav-offset{
	padding-top: 71px;
}
.navbar-brand{
	margin-right:2rem;
}
.navbar-brand{
	box-shadow: $card-box-shadow;
	width: 51px !important;
}
.navbar-nav .nav-link{
	letter-spacing: .1em;
}
.navbar-nav .nav-link:hover{
	color: $primary;
}
.fe-icon-menu{
	line-height:2;
}
.offcanvas-close{
	font-size: 2rem;
	top: 1.1rem;
}
.mobile-menu .mobile-menu-link.active::before{
	width:2px;
}

// HOME

.home{
	background-color: $gray-100;

	h1{
		font-family: cortado, sans-serif;
		font-style: normal;
		font-weight: 400;
		background: -webkit-linear-gradient(0deg, $gradient-start, $gradient-end);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		line-height:normal !important;
	}

	.come-in{
		text-transform: lowercase;
		color: $primary !important;
		font-size: $font-size-base;
		letter-spacing: 0;
		padding: 0 1.25rem;
	}

	.portrait{
		max-width: 120px;
	}

	// Rain Animation
	.rainWrapper{
		position: fixed;
		width: 100%;
		height: 100%;
		overflow: hidden;
	}
	
	.rain {
		@extend .bg-gradient-vertical;
		height: 15px;
		position: absolute;
		width:2px;
	}
	
	$rain: 200;
	
	@for $i from 1 through $rain {
		$top: (random(50) + 50) * 1%;
		$left: random(100) * 1%;
		$opacity: (random(30) + 30) * 0.01;
		$delay: random(10) - 1s;
	
		.rain:nth-of-type(#{$i}) {
			animation-name: rain-#{$i};
			animation-delay: $delay;
			animation-duration: random(3) + 3s;
			animation-iteration-count: infinite;
			animation-timing-function: linear;
			left: $left;
			opacity: 0.3;
			top: -$top;
		}
	
		@keyframes rain-#{$i} {
			0% {
				left: $left;
				top: -$top;
			}
			100% {
				top: 150%;
			}
		}
	}

	/*
	// Slide out tagline
	.textLeft, .textRight {
		display:inline-block;
			position:relative;
			line-height:2em;
			overflow:hidden;
			width:auto;
			margin:0 auto;
			padding: 1rem 0 2rem;
	}

	.textLeft{
		height:44px;
	}

	.fadingEffectLeft, .fadingEffectRight{
		position:absolute;
		width:100%;
		background:white;
		z-index: 2;
	}

	.fadingEffectLeft {
			top:0; bottom:0; right:0;
			animation: showHideLeft 2s linear alternate infinite;
	}

	.fadingEffectRight {
			top:0; bottom:0; left:0;
			animation: showHideRight 2s linear alternate infinite;
	}
	
	@keyframes showHideLeft {
			0% {width:0%}
			100% {width:100%;}
	}
	
	@keyframes showHideRight {
			0% {width:0%}
			100% {width:100%;}
	}
	*/
}

// PORTFOLIO - MAIN

.nav-link{
	padding: .5625rem .7rem;
}

.sub-filter .nav-item .nav-link{
	text-transform: capitalize;
	letter-spacing: 0;
	font-size: $font-size-base;
	font-weight:600;
}

// PORTFOLIO - INNER

.portfolio-inner .page-title{
	display: none !important;
}

.widget-title::after{
	background: #e7e7e7 !important;
}

figcaption{
	padding-right:1rem;
	padding-left:1rem;
}

// CONTACT

.g-recaptcha{
	margin-bottom: 1.5rem;
}

// THANK YOU

.thank-you{
	background: #f8f8f8;
}

// FOOTER

footer{
	background-color: $gray-100;

	p::before{
		display: block;
		position: relative;
		top: -20px;
		width: 100%;
		height: 1px;
		@extend .bg-gradient;
		content: '';
	}
}